import enFlag from "../assets/img/united-states.png";
import frFlag from "../assets/img/france.png";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";



function Language(props) {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(frFlag);

    function handleCookie(e) {
        localStorage.setItem("i18next", e);
        i18n.changeLanguage(e);
        handleChangeLanguage(e);
    }

    const handleChangeLanguage = (e) => {
        if (e === "en") {
            setLanguage(enFlag)
        } else {
            setLanguage(frFlag)
        };
    }

    useEffect(() => {
        if (localStorage.getItem("i18next") === "en")
            setLanguage(enFlag);
        else
            setLanguage(frFlag);
    }, []);

    return (
        <div className={"dropdown pt-0 mr-1"}>
            <Dropdown className={props.paddingTop}>
                <Dropdown.Toggle variant="dropdown-basic" className="w-auto h-40px text-white pt-0 pb-0">
                    <div className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto" id="kt_quick_user_toggle">
                        <img src={language} alt="img" width={20} height={20} />
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-180px">
                    <Dropdown.Item href="#" className="navi-link border-bottom" onClick={() => handleCookie('en')}>
                        <img src={enFlag} width="20" alt="img" height="20" /> <span className="navi-text font-weight-bolder"><span className="symbol symbol-20 mr-1"></span> English</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="navi-link" onClick={() => handleCookie('fr')}>
                        <img src={frFlag} alt="img" width="20" height="20" /> <span className="navi-text font-weight-bolder"> <span className="symbol symbol-20 mr-1"></span> Francais</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default Language;