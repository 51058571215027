import Axios from "axios";
import { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { HEADERS } from "../globals";
import toast from "react-hot-toast";
import moment from 'moment';


export default function CloseTask(props) {
    const myRefnameU = useRef(null);

    const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));
    const [Messagedate, setMessageDate] = useState("");

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const close = (a, b, e) => {
        if (date !== "")
            Axios.post("/api/task/close_task", {
                task_id: a,
                date: moment(b),
            }, HEADERS).then((response) => {
                if (response.status === 200 && response.data.type_action === true) {
                    notifySuccess("Task ended\n");
                    e.onHide();
                } else notifyError(response.data.errormsg);
            });
        else setMessageDate("Please enter a date");
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Close Task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={null}>
                        <div className="form-group mb-4 mt-3">
                            <div className="font-weight-bold fs-15">
                                <p>Task : <em className="fs-14">{props.info.task}</em></p>
                                <p>Total already attempt : <em className="fs-14">{props.info.total} minute(s)</em></p>
                            </div>
                            <div className="text-center font-weight-bold fs-17">
                                Choose an ended date for Task #{props.info.id_task}
                            </div>
                            <input
                                type="datetime-local"
                                id="ticket-subject"
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                                value={date}
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {Messagedate}
                            </div>
                        </div>
                        <button type="submit" ref={null} hidden className="btn">
                            Submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button ref={myRefnameU} onClick={props.onHide} variant="default">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        onClick={() => close(props.info.id_task, date, props)}
                        variant="danger"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
