import axios from "axios"
// import url from "../globals"
import { toast } from "react-hot-toast"
import { HEADERS } from '../globals'

export const getUsers = (callback) => {
    axios.post('/api/user/getUsers', {},
        HEADERS)
        .then((response) => {
            callback(response)
        })
}

export const getUsersDepartment = (dpt, callback) => {
    axios.post('/api/user/getUsersDepartment', { dpt },
        HEADERS)
        .then((response) => {
            callback(response)
        })
}

export const User = {
    userId: -1,
    userName: '',
    userDepartementCode: -1,
    userDepartement: '',
    userPassword: '',
    perms: []
}

export const addUser = (user, pwd, callback) => {
    axios.post('/api/user/addUser', {
        user,
        pwd,
        author: sessionStorage.getItem('id')
    }, HEADERS).then((response) => {
        callback(response)
    })
}

export const editUser = (user, callback) => {
    axios.post('/api/user/editUser', {
        user,
        author: sessionStorage.getItem('id')
    }, HEADERS).then((response) => {
        callback(response)
    })
}

export const notifySuccess = (msg) =>
    toast.success(msg, {
        style: {
            padding: "16px",
            backgroundColor: "rgba(124, 140, 135, 0.70)",
            color: "#FFF",
            maxWidth: "280px",
            textAlign: "left",
            fontSize: "13px",
        },
    });


export const notifyError = (msg) =>
    toast.error(msg, {
        style: {
            padding: "16px",
            backgroundColor: "rgba(124, 140, 135, 0.70)",
            color: "#FFF",
            maxWidth: "280px",
            textAlign: "left",
            fontSize: "13px",
        },
    });

export const getPermissions = (callback) => {
    axios.post('/api/user/getPermissions', {}, HEADERS).then((response) => {
        callback(response)
    })
}

export const getAllPermissionsUsers = (callback) => {
    axios.post('/api/user/getAllPermissionsUsers', {}, HEADERS).then((response) => {
        callback(response)
    })
}

export const getPermissionsUser = (userId, callback) => {
    axios.post('/api/user/getPermissionsUser', { userId }, HEADERS).then((response) => {
        callback(response)
    })
}

export const resetPassword = (userId, userPassword, callback) => {
    axios.post("/api/user/resetPassword", {
        id: userId,
        password: userPassword
    }, HEADERS).then((response) => {
        callback(response)
    })
}