
import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { notifyError, notifySuccess } from "../Function/User";
import { addDepartment } from "../Function/Department";

export function ModalAddDepartment({ open, setOpen, setRefresh }) {
    const [name, setName] = useState('')
    const [errName, setErrName] = useState('')

    const handleAddDepartment = () => {
        var err = false
        if (name === "") { err = true; setErrName("This field are required") } else { setErrName("") }

        if (!err) {
            addDepartment(name, (response) => {
                if (response.data.status) {
                    setRefresh(true)
                    setOpen(false)
                    notifySuccess('Department has been added successfully')
                } else {
                    notifyError('Something went wrong')
                }
            })
        }
    }


    useEffect(() => {
        if (open) {

        } else {
            setName('')
            setErrName('')
        }
    }, [open])

    return (
        <Modal show={open} onHide={() => { setOpen(false) }} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title onClick={() => { }}>
                    Add Department
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group mb-1 mt-1 row g-2 ">
                    <label className="font-weight-bold">
                        Department name<span className="text-danger pl-3">*</span>
                    </label>
                    <input
                        className="form-control rounded-0"
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        value={name}
                    />
                    <div id="ticketprio-error" className="text-danger pt-2">
                        {errName}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => { setOpen(false) }}>Close</button>
                <button type="button" className="btn btn-success" onClick={() => { handleAddDepartment() }}>Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}