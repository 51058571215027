
import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { addCategorie, categories, editCategorie } from "../Function/Department";
import { notifyError, notifySuccess } from "../Function/User";

export function ModalAddCategory({ open, setOpen, setRefresh, dpt, type }) {
    const [cat, setCat] = useState(categories)
    const [errCatName, setErrCatName] = useState('')
    const [errCatTime, setErrCatTime] = useState('')

    const handleAddCategory = () => {
        var err = false
        if (cat.catName === "") { err = true; setErrCatName("This field are required") } else { setErrCatName("") }
        if (cat.catTime < 1) { err = true; setErrCatTime("This field are required") } else { setErrCatTime("") }

        if (!err) {
            addCategorie(cat, (response) => {
                if (response.data.status) {
                    setRefresh(true)
                    setOpen(false)
                    notifySuccess('Category has been added successfully')
                } else {
                    notifyError('Something went wrong')
                }
            })
        }
    }

    const handleEditCategory = () => {
        var err = false
        if (cat.catName === "") { err = true; setErrCatName("This field are required") } else { setErrCatName("") }
        if (cat.catTime < 1) { err = true; setErrCatTime("This field are required") } else { setErrCatTime("") }

        if (!err) {
            editCategorie(cat, (response) => {
                if (response.data.status) {
                    setRefresh(true)
                    setOpen(false)
                    notifySuccess('Category has been added successfully')
                } else {
                    notifyError('Something went wrong')
                }
            })
        }
    }

    useEffect(() => {
        if (open) {
            if (type === 'edit') {
                setCat(() => { return { ...cat, catId: dpt.ctg_id, catDpt: dpt.ctg_department, catName: dpt.ctg_name, catTime: dpt.ctg_time } })
            } else {
                setCat(() => { return { ...cat, catDpt: dpt } })
            }
        } else {
            setCat(categories)
        }
    }, [open])

    return (
        <Modal show={open} onHide={() => { setOpen(false) }} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group mb-1 mt-1 row g-2 ">
                    <div className="col-md-6">
                        <label className="font-weight-bold">
                            Category name<span className="text-danger pl-3">*</span>
                        </label>
                        <input
                            className="form-control rounded-0"
                            onChange={(e) => {
                                setCat(() => { return { ...cat, catName: e.target.value } })
                            }}
                            value={cat.catName}
                        />
                        <div id="ticketprio-error" className="text-danger pt-2">
                            {errCatName}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="font-weight-bold">
                            Categorie Estimated time (mn)<span className="text-danger pl-3">*</span>
                        </label>
                        <input
                            type="number"
                            className="form-control rounded-0"
                            onChange={(e) => {
                                setCat(() => { return { ...cat, catTime: parseInt(e.target.value, 10) } })
                            }}
                            value={cat.catTime}
                        />
                        <div id="ticketprio-error" className="text-danger pt-2">
                            {errCatTime}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => { setOpen(false) }}>Close</button>
                <button type="button" className="btn btn-success" onClick={() => { if (type !== 'edit') { handleAddCategory() } else { handleEditCategory() } }}>Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}