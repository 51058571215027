import { useState } from 'react';
import { BiChevronDownCircle, BiEditAlt, BiSend } from 'react-icons/bi';
import { BiChevronUpCircle } from 'react-icons/bi';
import { ModalAddCategory } from '../Modal/category';
import { department, editDepartment } from '../Function/Department';
import { FcCancel } from 'react-icons/fc';
import { notifyError, notifySuccess } from '../Function/User';

export default function AccordionDepartment({ list, listCategorie, setRefreshList, setRefreshCtg, permissions, showAll }) {
    const [departementChoosed, setDepartementChoosed] = useState(-1);
    const [modalType, setModalType] = useState('')
    const [modalCategory, setModalCategory] = useState(false)
    const [editField, setEditField] = useState(false)
    const [dpt, setDpt] = useState(department)
    const [errDpt, setErrDpt] = useState('')
    const [openIndex, setOpenIndex] = useState(null)


    function handleCloseAccordion(id) {
        if (openIndex === id)
            setOpenIndex(null)
        else
            setOpenIndex(id)
    }

    function handleEditField() {
        if (dpt.dpt_name !== '') {
            setErrDpt('')
            editDepartment(dpt, (response) => {
                if (response.data.status) {
                    setRefreshList(true);
                    setEditField(false)
                    setDpt(department)
                    notifySuccess('Department has been edited successfully')
                } else {
                    notifyError('Something went wrong')
                }
            })
        } else {
            setErrDpt('This field are required')
        }
    }

    return (
        <>
            <ModalAddCategory open={modalCategory} setOpen={setModalCategory} setRefresh={setRefreshCtg} dpt={departementChoosed} type={modalType} />
            {list.map((e, index) => {
                if (showAll || e.dpt_id.toString() === sessionStorage.getItem("dpt"))
                    return (
                        <div className="border mt-2 " key={`myAccordion-${index}`}>
                            <div className='p-5 bg-gray-200 cursor-pointer' onClick={() => { handleCloseAccordion(index); }}>{e.dpt_name} {openIndex === index ? <BiChevronUpCircle size={20} className="float-right " /> : <BiChevronDownCircle size={20} className="float-right cursor-pointer" />}</div>
                            <div hidden={openIndex === index ? false : true}>
                                <div className='p-5'>
                                    <div className=''>Department :
                                        {editField && dpt.dpt_id === e.dpt_id ?
                                            <>
                                                <input
                                                    className="rounded-0 border-light-success"
                                                    onChange={(f) => {
                                                        setDpt(() => { return { ...dpt, dpt_name: f.target.value } })
                                                    }}
                                                    value={dpt.dpt_name}
                                                />
                                                <BiSend size={20} className='text-info cursor-pointer hover_underlined' onClick={() => { handleEditField() }} />
                                                <FcCancel size={20} className='text-danger cursor-pointer hover_underlined' onClick={() => { setDpt(department); setEditField(false); setErrDpt('') }} />
                                                <div id="ticketprio-error" className="text-danger pt-2">
                                                    {errDpt}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <span className='font-weight-bold'>{e.dpt_name}</span>
                                                {permissions.filter(perm => perm.userPerm_code === 119)[0].userPerm_value === 1 &&
                                                    <BiEditAlt size={15} className='text-info cursor-pointer hover_underlined' onClick={() => { setEditField(true); setDpt(() => { return { ...dpt, dpt_id: e.dpt_id, dpt_name: e.dpt_name } }) }} />}
                                            </>
                                        }
                                    </div>
                                    <div className=''>Categorie : </div>
                                    <table className='table border'>
                                        <thead className='bg-gray-200 border'>
                                            <tr>
                                                <th>Name</th>
                                                <th className=''>Estimated Time</th>
                                                {permissions.filter(perm => perm.userPerm_code === 120)[0].userPerm_value === 1 &&
                                                    <th className='cursor-pointer bg-info text-white bg-hover-danger' onClick={() => { setModalCategory(true); setDepartementChoosed(e.dpt_id); setModalType('add') }}>ADD</th>}
                                            </tr>
                                        </thead>
                                        <tbody className='border'>
                                            {listCategorie.map((d, index) => {
                                                if (d.ctg_department.toString() === e.dpt_id.toString())
                                                    return (
                                                        <tr key={index}>
                                                            <td className='border-right'>{d.ctg_name}</td>
                                                            <td className='border-right'>{d.ctg_time} minute(s)</td>
                                                            {permissions.filter(perm => perm.userPerm_code === 121)[0].userPerm_value === 1 &&
                                                                <>
                                                                    <td className='text-primary cursor-pointer hover_underlined' onClick={() => { setModalType('edit'); setModalCategory(true); setDepartementChoosed(d); }}>edit </td>
                                                                </>
                                                            }
                                                        </tr>
                                                    )
                                            })}

                                        </tbody>
                                    </table>

                                </div>
                                <div className='h-50px bg-gray-200 w-100'>
                                    <button
                                        className="btn btn-defaut text-white bg-red font-weight-bold float-right m-2"
                                        onClick={() => {
                                            handleCloseAccordion(e.dpt_id)
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>

                        </div>
                    )
            })}

        </>
    );
}