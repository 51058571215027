
import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { User, addUser, editUser, getPermissionsUser, notifyError, notifySuccess } from "../Function/User"
import { FcNext } from 'react-icons/fc';
import { getDepartment } from "../Function/Department";

export function ModalAddUser({ open, setOpen, setRefresh, type, userChoosed, editPerm, allDepartment, advSetting }) {
    var bcrypt = require("bcryptjs");
    var salt = bcrypt.genSaltSync(10);
    const [listDepartment, setListDepartment] = useState([])
    const [user, setUser] = useState(User)
    const [permissionView, setPermissionView] = useState(false);
    const [actualPage, setActualPage] = useState('TASK')
    const [userValidate, setuserValidate] = useState(false)

    const handleAddUser = () => {
        var hash = bcrypt.hashSync(user.userPassword, salt);
        addUser(user, hash, (response) => {
            if (response.data.status) {
                setOpen(false)
                setRefresh(true)
                notifySuccess('User added successfully')
            }
        })
    }

    const handleEditUser = () => {
        editUser(user, (response) => {
            if (response.data.status) {
                setRefresh(true)
                setOpen(false)
                notifySuccess('User added successfully')
                setActualPage('TASK')
            } else {
                notifyError('Something went wrong')
            }
        })
    }

    const getPerms = () => {
        getPermissionsUser(userChoosed.id_user, (response) => {
            if (response.data.status) {
                setUser(() => { return { ...user, perms: response.data.list } })
            }
        })
    }

    const modifyPerms = (permCode, value) => {
        setUser(prevUser => ({
            ...prevUser,
            perms: prevUser.perms.map(d => {
                if (d.userPerm_code === permCode) {
                    return {
                        ...d,
                        userPerm_value: value ? 1 : 0
                    };
                } else {
                    return d;
                }
            })
        }));

    }

    const filterListPermission = (page) => {
        setActualPage(page)
    }

    useEffect(() => {
        if (open) {
            getDepartment((response) => {
                if (response.data.status) {
                    if (type === 'Edit') {
                        const index = response.data.list.findIndex(item => item.dpt_id === userChoosed.user_department);
                        const item = response.data.list.splice(index, 1)[0];
                        response.data.list.unshift(item);
                        setListDepartment(response.data.list);

                        setUser(() => {
                            return {
                                ...user,
                                userId: userChoosed.id_user,
                                userName: userChoosed.user_name,
                                userDepartement: userChoosed.dpt_name,
                                userDepartementCode: userChoosed.user_department,
                                user_active: userChoosed.user_active
                            }
                        });
                    } else {
                        const index = response.data.list.findIndex(item => item.dpt_id + "" === sessionStorage.getItem('dpt') + "");
                        const item = response.data.list.splice(index, 1)[0];
                        response.data.list.unshift(item);
                        setUser(() => { return { ...user, userDepartementCode: item.dpt_id } })
                        setListDepartment(response.data.list);
                        // setListDepartment(response.data.list)
                    }
                }
            })
        } else {
            setUser(User)
            setPermissionView(false)
        }
    }, [open])

    return (
        <Modal show={open} onHide={() => { setOpen(false) }} size={permissionView ? "lg" : 'md'} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {type === 'Add'
                        ? 'Add User'
                        :
                        <>
                            <div className={!permissionView ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => { setPermissionView(false) }}>{type}</div>

                            {editPerm ? <div className={permissionView ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => { setPermissionView(true); if (User.perms.length === 0) { getPerms() } }}>Permissions</div> : <></>}
                        </>
                    }

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!permissionView ?
                    <div className="form-group mb-1 mt-1 row g-2 ">
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Nom d'utilisateur<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                className="form-control rounded-0"
                                onChange={(e) => {
                                    setUser(() => { return { ...user, userName: e.target.value } });
                                }}
                                value={user.userName}
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {""}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Departement<span className="text-danger pl-3">*</span>
                            </label>
                            <select className="form-control rounded-0" onChange={(e) => {
                                setUser(() => { return { ...user, userDepartementCode: parseInt(e.target.value, 10) } })
                            }}
                                disabled={allDepartment ? false : true}
                            >
                                {/* {type !== 'Edit' &&
                                    <option>Choose ... </option>
                                } */}
                                {listDepartment.map((e, index) => {
                                    return (
                                        <option value={e.dpt_id} key={index}>{e.dpt_name}</option>
                                    )
                                })}
                            </select>
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {""}
                            </div>
                        </div>

                        {
                            type === "Edit" ?
                                <div className="flex item-center align-center col-md-6 mt-2 ">
                                    <input id="validate" type="checkbox" className="mt-1" checked={user.user_active === 1 ? true : false} onChange={(e) => {
                                        setUser(() => {
                                            return {
                                                ...user, user_active: e.target.checked ? 1 : 0
                                            }
                                        });
                                    }} /> <label htmlFor="validate" className="ml-1 cursor-pointer">Active User</label>
                                </div> : null
                        }

                        {type !== 'Edit' &&
                            <div className="col-md-12">
                                <label className="font-weight-bold">
                                    Mot de passe<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    type="password"
                                    className="form-control rounded-0"
                                    onChange={(e) => {
                                        setUser(() => { return { ...user, userPassword: e.target.value } })
                                    }}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {""}
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className="row">
                        <div className="col-lg-5 border-right">
                            <div className="fs-14 font-weight-bolder mb-3 pb-2 d-flex text-center">
                                <div className="border-bottom fs-14 font-weight-bolder pb-4 text-center mb-3 ">PAGE</div>
                            </div>

                            <div className={`form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 ${actualPage === 'TASK' && 'choosen1'}`} onClick={() => { filterListPermission('TASK') }}>
                                <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer">TASK</label>
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1" ></div>
                                <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' />
                            </div>
                            {advSetting ?
                                <div className={`form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 ${actualPage === 'USER' && 'choosen1'}`}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('USER'); }} >USER  </label>
                                    <input type='checkbox' className="col-lg-1 col-md-1 col-sm-1 col-1" checked={user.perms.length > 0 && user.perms.filter(d => d.userPerm_code === 109)[0].userPerm_value} onChange={(e) => { modifyPerms(109, e.target.checked) }} />
                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { }} />
                                </div>
                                :
                                <></>
                            }
                            <div className={`form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 ${actualPage === 'DEPARTMENT' && 'choosen1'}`}>
                                <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('DEPARTMENT') }} >DEPARTMENT  </label>
                                <input type='checkbox' className="col-lg-1 col-md-1 col-sm-1 col-1" checked={user.perms.length > 0 && user.perms.filter(d => d.userPerm_code === 110)[0].userPerm_value} onChange={(e) => { modifyPerms(110, e.target.checked) }} />
                                <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { }} />
                            </div>
                            <div className={`form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 ${actualPage === 'CATEGORIE' && 'choosen1'}`}>
                                <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('CATEGORIE') }} >CATEGORIE</label>
                                {/* <input type='checkbox' className="col-lg-1 col-md-1 col-sm-1 col-1" checked={user.perms.length > 0 && user.perms.filter(d => d.userPerm_code === 111)[0].userPerm_value} onChange={(e) => { modifyPerms(111, e.target.checked) }} /> */}
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1" ></div>
                                <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { }} />
                            </div>
                            {user.perms.filter(d => d.userPerm_code === 123)[0] && sessionStorage.getItem('id') === 1 &&
                                <div className={`form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 `}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { }} >ADVANCED SETTINGS</label>
                                    <input type='checkbox' className="col-lg-1 col-md-1 col-sm-1 col-1" checked={user.perms.length > 0 && user.perms.filter(d => d.userPerm_code === 123)[0].userPerm_value} onChange={(e) => { modifyPerms(123, e.target.checked) }} />
                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { }} />
                                </div>
                            }
                        </div>

                        <div className="col-lg-7" >
                            <div className="border-bottom fs-14 font-weight-bolder pb-4 text-center mb-3 ">PERMISSIONS FOR PAGE</div>
                            <div style={{ height: '300px', overflow: 'auto', }}>
                                {user.perms.map((d, index) => {
                                    if (d.perm_name.toLowerCase().includes(actualPage.toLowerCase())) {
                                        if ((d.perm_id === 122 && advSetting) || (d.perm_id !== 122))
                                            return (
                                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2" key={index}>
                                                    <label className="font-weight-bolder col-lg-9 col-md-9 col-sm-9 col-9 cursor-pointer" htmlFor={d.userPerm_code}>{d.perm_name}</label>
                                                    <input type='checkbox' id={d.userPerm_code} className="col-lg-1 col-md-1 col-sm-1 col-1 cursor-pointer" checked={d.userPerm_value} onChange={(e) => { modifyPerms(d.userPerm_code, e.target.checked) }} />
                                                </div>
                                            );
                                    }
                                })

                                }
                            </div>

                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => { setOpen(false) }}>Close</button>
                <button type="button" className="btn btn-success" onClick={() => { if (type !== 'Edit') { handleAddUser() } else { handleEditUser() } }}>Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}