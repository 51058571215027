import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/custom/css/style.bundle.css";
import "./assets/custom/plugins/global/plugins.bundle.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Authentication from "./Authentication";
import User from "./components/User";
import Department from "./components/Department";

function App() {

  return (
    <Router>
      <div className="App">
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/Login" element={<Login />} />
                <Route element={<Authentication />}>
                  <Route exact path="/home" element={<Home />} />
                  <Route exact path="/user" element={<User />} />
                  <Route exact path="/department" element={<Department />} />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
