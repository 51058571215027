import { useEffect, useState } from "react";
import MenuTestPage from "./header_mobile";
import { HiPlus } from 'react-icons/hi';
import { BiGroup, BiRefresh } from "react-icons/bi";
import { MdPerson } from "react-icons/md";
import { notifyError } from "../Function/User";
import AccordionDepartment from "./AccordionDepartment";
import { getPermissionsUser } from "../Function/User";
import { getCategorie, getDepartment } from "../Function/Department";
import { ModalAddDepartment } from "../Modal/department";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";

export default function Department() {
    const [refresh, setRefresh] = useState(false)
    const [refreshCategorie, setRefreshCategorie] = useState(false)
    const [listDepartment, setListDepartment] = useState([])
    const [listCategorie, setListCategorie] = useState([])
    const [permissions, setPermission] = useState([])
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const [modalAddDepartment, setModalAddDepartment] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const navigate = useNavigate();

    const getRequired = () => {
        try {
            getDepartment((response) => {
                if (response.data.status) {
                    setListDepartment(response.data.list)
                } else {
                    notifyError('Internal Error')
                }
            })

            getCategorie((response) => {
                if (response.data.status) {
                    setListCategorie(response.data.list)
                } else {
                    notifyError('Internal Error')
                }
            })

            setLoadingPermissions(true);
            getPermissionsUser(sessionStorage.getItem("id"), (response) => {
                if (response.data.status) {
                    setPermission(response.data.list)
                    if (response.data.list.filter(perm => perm.userPerm_code === 110)[0].userPerm_value === 0) {
                        navigate('/Home')
                    }
                    setLoadingPermissions(false);
                }
            })
        } catch (error) {
            notifyError('Internal Error')
        }
    }

    useEffect(() => {
        if (refresh) {
            getRequired()
        }
    }, [refresh])

    useEffect(() => {
        if (refreshCategorie) {
            getCategorie((response) => {
                if (response.data.status) {
                    setListCategorie(response.data.list)
                } else {
                    notifyError('Internal Error')
                }
            })
            setRefreshCategorie(false)
        }

    }, [refreshCategorie])

    useEffect(() => {
        getRequired()
    }, [])
    return (
        <div>

            <MenuTestPage />
            <ModalAddDepartment open={modalAddDepartment} setOpen={setModalAddDepartment} setRefresh={setRefresh} />
            {!loadingPermissions &&
                <div className="col-xl-12 center-block" align="center">
                    <div className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px" >
                        <div className="pt-10 w-100 h-80px" style={{ marginLeft: "0.8rem" }}>
                            <h3 className="float-left pt-2 pr-3">List of department</h3>
                            <span className="float-left">
                                <BiRefresh
                                    onClick={() => {
                                        getRequired()
                                    }}
                                    className="cursor-pointer display-4"
                                />
                            </span>

                            {permissions.filter(perm => perm.userPerm_code === 118)[0].userPerm_value === 1 &&
                                <button onClick={() => { setModalAddDepartment(true) }} className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-10" >
                                    <HiPlus size={20} />
                                </button>
                            }
                            {permissions.filter(perm => perm.userPerm_code === 122)[0].userPerm_value === 1 ?

                                <button
                                    onClick={() => {
                                        if (showAll) {
                                            setShowAll(false);
                                        } else {
                                            setShowAll(true);
                                        }
                                    }} className="btn btn-defaut text-white bg-info font-weight-bold float-right mr-10" >

                                    {showAll ? <MdPerson /> : <BiGroup size={20} />}

                                </button> : <></>}
                        </div>
                        <div className="p-10 min-h-550px max-h-550px overflow-auto" align="left">
                            <AccordionDepartment list={listDepartment} setList={setListDepartment} listCategorie={listCategorie} setListCategorie={setListCategorie} setRefreshList={setRefresh} setRefreshCtg={setRefreshCategorie} permissions={permissions} showAll={showAll} />
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}