import axios from "axios"
import { HEADERS } from "../globals"

export const categories = {
    catId: null,
    catDpt: -1,
    catName: '',
    catTime: 0
}

export const department = {
    dpt_id: -1,
    dpt_name: ''
}

export const getDepartment = (callback) => {
    axios.post('/api/department/getDepartment', {}, HEADERS).then((response) => {
        callback(response)
    })
}

export const getCategorie = (callback) => {
    axios.post('/api/department/getCategorie', {}, HEADERS).then((response) => {
        callback(response)
    })
}

export const addCategorie = (category, callback) => {
    axios.post('/api/department/addCategorie', { category }, HEADERS).then((response) => {
        callback(response)
    })
}

export const editCategorie = (category, callback) => {
    axios.post('/api/department/editCategorie', { category }, HEADERS).then((response) => {
        callback(response)
    })
}

export const removeCategorie = (category, callback) => {
    axios.post('/api/department/removeCategorie', { category }, HEADERS).then((response) => {
        callback(response)
    })
}

export const addDepartment = (name, callback) => {
    axios.post('/api/department/addDepartment', { name }, HEADERS).then((response) => {
        callback(response)
    })
}

export const editDepartment = (department, callback) => {
    axios.post('/api/department/editDepartment', { department }, HEADERS).then((response) => {
        callback(response)
    })
}