import { useState } from 'react';
import { BiChevronDownCircle } from 'react-icons/bi';
import { BiChevronUpCircle } from 'react-icons/bi';
import { ModalAddUser } from '../Modal/User';
import { ModalPassword } from '../Modal/password';

export default function ControlledAccordions({ list, setList, setRefreshList, showAll, permissions }) {
    const [typeModal, setTypeModal] = useState('');
    const [modalUser, setModalUser] = useState(false)
    const [ModalResetPassword, setModalResetPassword] = useState(false)
    const [userChoosed, setUserChoosed] = useState([])
    const [openIndex, setOpenIndex] = useState(null)

    function handleCloseAccordion(id) {
        if (openIndex === id)
            setOpenIndex(null)
        else
            setOpenIndex(id)
    }

    return (
        <>
            <ModalAddUser open={modalUser} setOpen={setModalUser} setRefresh={setRefreshList} type={typeModal} userChoosed={userChoosed} editPerm={permissions.filter(perm => perm.userPerm_code === 116)[0].userPerm_value} allDepartment={permissions.filter(perm => perm.userPerm_code === 122)[0].userPerm_value} advSetting={permissions.filter(perm => perm.userPerm_code === 123)[0].userPerm_value} />
            <ModalPassword open={ModalResetPassword} setOpen={setModalResetPassword} setRefresh={setRefreshList} userId={userChoosed.id_user} />
            {list.map((e, index) => {
                if (showAll || e.user_department + "" === sessionStorage.getItem("dpt") + "")
                    return (
                        <div className="border mt-2 " key={`myAccordion-${index}`}>
                            <div className='p-5 bg-gray-200 cursor-pointer' onClick={() => { handleCloseAccordion(index) }}>{e.user_name} {e.seen ? <BiChevronDownCircle size={20} className="float-right " /> : <BiChevronUpCircle size={20} className="float-right cursor-pointer" />}</div>
                            <div hidden={openIndex === index ? false : true}>
                                <div className='p-5'>
                                    <div className=''>Username : <span className='font-weight-bold'>{e.user_name}</span></div>
                                    <div className=''>Department : <span className='font-weight-bold'>{e.dpt_name}</span></div>
                                </div>
                                <div className='h-50px bg-gray-200 w-100'>
                                    {permissions.filter(perm => perm.userPerm_code === 115)[0].userPerm_value === 1 &&
                                        <button
                                            className="btn btn-defaut text-white bg-red font-weight-bold float-right m-2"
                                            onClick={() => {
                                                setTypeModal('Edit')
                                                setUserChoosed(e)
                                                setModalUser(true)
                                            }}
                                        >
                                            Edit
                                        </button>
                                    }
                                    {permissions.filter(perm => perm.userPerm_code === 117)[0].userPerm_value === 1 &&
                                        <button
                                            className="btn btn-info text-white bg-red font-weight-bold float-right m-2"
                                            onClick={() => {
                                                setUserChoosed(e)
                                                setModalResetPassword(true)
                                            }}
                                        >
                                            Change Password
                                        </button>
                                    }
                                </div>
                            </div>

                        </div>
                    )
            })}

        </>
    );
}