import axios from "axios"
import { HEADERS } from '../globals'

export const updateCat = {
    task_id: -1,
    prev_cat_id: -1,
    cat_id: -1,
    cat_time: -1
}

export const editCategorieTask = (categorie, callback) => {
    axios.post('/api/task/editCategoryTask', {
        categorie
    }, HEADERS).then((response) => {
        callback(response)
    })
}

export const completeAllTask = (list, callback) => {
    axios.post('/api/task/completeAll', {
        list
    }, HEADERS).then((response) => {
        callback(response)
    })
}