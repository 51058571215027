import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/auto-logo-circle.png";
import Axios from "axios";
import { toast } from "react-hot-toast";
import { HEADERS } from "../globals";
var bcrypt = require("bcryptjs");

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState();


    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const handleSubmit = (e) => {
        try {
            e.preventDefault();
            setErrorMessage("");
            Axios.post("/api/credential/loginuser", {
                username: username,
                password: password,
            }, HEADERS).then((response) => {
                if (response.data.result === true) {
                    if (bcrypt.compareSync(password, response.data.ref)) {
                        localStorage.setItem("username", response.data.user);
                        localStorage.setItem("id", response.data.id);
                        sessionStorage.setItem("id", response.data.id);
                        sessionStorage.setItem("username", response.data.user);
                        sessionStorage.setItem("dpt", response.data.department);
                        navigate("/home");
                    } else {
                        setErrorMessage("Incorrect password");
                    }
                } else {
                    notifyError(response.data.msg)
                    setErrorMessage(response.data.errMessage);
                }
            });

        } catch (error) {
        }
    };

    if (localStorage.getItem("i18next") === null) {
        localStorage.setItem("i18next", "fr");
    }

    useEffect(() => {
        localStorage.clear()
    }, []);

    return (
        <div
            className="mt-auto bg-white"
            style={{ minHeight: "100vh", position: "relative" }}
        >
            <div
                className="d-flex flex-column w-100 flex-root"
                style={{
                    margin: 0,
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div className="login login-2 d-flex flex-row-fluid" id="kt_login">
                    <div className="d-flex flex-center flex-row-fluid p-0">
                        <div className="login-form text-center position-relative overflow-hidden">
                            <div className="d-flex flex-center mb-3">
                                <span >
                                    <img src={logo} className="max-h-150px" alt="" />
                                </span>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="login-signin pb-5 pt-3">
                                    <div className="mb-3">
                                        <h2>Sign in</h2>
                                    </div>
                                    <div className="mb-8 mt-5">
                                        <div className="text-muted fs-11-5">
                                            Enter your login informations
                                        </div>
                                    </div>
                                    <div id="login-form">
                                        <div className="form-group mb-5">
                                            <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                }}
                                                className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5 min-w-100"
                                                required
                                                placeholder="username"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                }}
                                                className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5"
                                                placeholder="Password"
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                        <div className="form-group d-flex justify-content-between align-items-center mt-6">
                                            <div className="checkbox-inline mr-20 pl-0">
                                                <label className="checkbox m-0 text-muted min-w-250px">
                                                    <input
                                                        type="checkbox"
                                                        name="remember"
                                                        id="remember-me"
                                                    />
                                                    <span></span>
                                                    Remember me
                                                </label>
                                            </div>
                                            <span></span>
                                        </div>
                                        <p id="error-text" className="text-danger pt-0">
                                            {errorMessage}
                                        </p>
                                        <button
                                            type="submit"
                                            id="check-user"
                                            className="btn btn-lg text-white rounded-0 btn-block fs-15-5 bg-red"
                                            name="btn-connexion"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
