
import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { notifyError, resetPassword } from "../Function/User";
export function ModalPassword({ open, setOpen, setRefresh, userId }) {
    var bcrypt = require("bcryptjs");
    var salt = bcrypt.genSaltSync(10);
    const [confirmPwd, setConfirmPwd] = useState(false)
    const [newPassword, setNewUserPassword] = useState('')


    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function generatePwd() {
        var password = makeid(5);
        var hash = bcrypt.hashSync(password, salt);

        resetPassword(userId, hash, (response) => {
            if (response.data.status) {
                setNewUserPassword(password)
            } else {
                notifyError('Something went wrong')
            }
        })
    }

    useEffect(() => {
        if (open) {

        } else {
            setConfirmPwd(false)
        }
    }, [open])

    return (
        <Modal show={open} onHide={() => { setOpen(false) }} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!confirmPwd ?
                    'Are you sure'
                    :
                    <form>

                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                New Password
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <div id="ticketprio-error" className="text-danger pt-2">
                                <label>The new password is {newPassword}</label>
                            </div>
                        </div>

                    </form>}
                {/* <div className="col-md-6">
                        <label className="font-weight-bold">
                            Nom d'utilisateur<span className="text-danger pl-3">*</span>
                        </label>
                        <input
                            className="form-control rounded-0"
                            onChange={(e) => {
                            }}
                        />
                        <div id="ticketprio-error" className="text-danger pt-2">
                            {""}
                        </div>
                    </div> */}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => { setOpen(false) }}>Close</button>
                {!confirmPwd && <button type="button" className="btn btn-success" onClick={() => { setConfirmPwd(true); generatePwd() }}>Confirm</button>}
            </Modal.Footer>
        </Modal>
    )
}