import MenuTestPage from "./header_mobile";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { HEADERS } from "../globals";
import StartTask from "./StartTask";
import CloseTask from "./CloseTask";
import { BiRefresh } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import Moment from "moment";
import { useDownloadExcel, downloadExcel } from "react-export-table-to-excel";
import { getPermissionsUser, getUsersDepartment } from "../Function/User";
import { getCategorie } from "../Function/Department";
import moment from 'moment-timezone';
import { completeAllTask, editCategorieTask, updateCat } from "../Function/Task";
function Home() {
    //Variable part
    const { t } = useTranslation();
    const [task, setTask] = useState("");
    const [estimate_time, setEstimate_time] = useState("0");
    const [categorie, setCategorie] = useState("");
    const [categorieID, setCategorieID] = useState("");
    const [project, setProject] = useState("");
    const [message_task, setMessage_task] = useState("");
    const [message_project, setMessage_project] = useState("");
    const [messageEstimate_time, setMessageEstimate_time] = useState("");
    const [messaageCategorie, setMessaageCategorie] = useState("");
    const [showModalSeen, setShowModalSeen] = useState(false);
    const [showModalClose, setShowModalClose] = useState(false);
    const [getmaintenancedata, setMaintenanceData] = useState([]);
    const [getdetailstask, setGetdetailstask] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [id_task, setId_task] = useState(0);
    const [showType, setShowType] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const linePerPage = 10;
    const [all_task, setAll_task] = useState([]);
    const [all_task1, setAll_task1] = useState([]);
    const [filter_test, setFilter_test] = useState("");
    const [showModelfilter, setShowModelfilter] = useState(false);
    const [filterType, setFilterType] = useState(-1)
    const [filterTexte, setFilterTexte] = useState('')
    const messageMch_filter = false
    const [messageAssign, setMessageAssign] = useState("");
    const [all_user, setAll_user] = useState([]);
    const [assignto, setAssigned_to] = useState(0);
    const [showDatefilter, setShowDatefilter] = useState(false);
    const [showall, setshowall] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const messageFromDate = "";
    const MessageToDate = "";
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const [permissions, setPermission] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [taskChoosed, setTaskChoosed] = useState([])
    const [listUserDepartment, setListUserDepartment] = useState([])

    const [editCtg, setEditCtg] = useState(false)
    const [taskEdit, setTaskEdit] = useState([])
    const [inputChecked, setInputChecked] = useState(false)
    const [dateHours, setdateHours] = useState(moment().format().split('T')[0])
    const [hoursDone, sethoursDone] = useState(0)
    const [selectedUser, setselectedUser] = useState(localStorage.getItem('id'))
    const header = [
        "#",
        "Project",
        "Task",
        "Resolved by",
        "Categorie",
        "Extimated time",
        "Status",
        "Start date",
        "End date",
        "Total time",
    ];

    const [updCat, setUpdCat] = useState(updateCat)

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const handleModelClosefilter = () => {
        setShowModelfilter(false);
        setShowDatefilter(false);
        setFilter_test("");
    };

    function handleDownloadExcel() {
        get_data_repport();
    }

    const restartTask = async (d) => {
        await Axios.post("/api/task/restart_task", {
            task: d.id_task,
        }, HEADERS).then((response) => {
            if (response.data.affectedRows > 0) {
                setRefreshData(true);
                notifySuccess(response.data.msg);
            } else notifyError(response.data.err);
        });
        calculateHoursDone(selectedUser)
    };

    const get_data_repport = async () => {
        await Axios.post("/api/task/make_repport", {
            date_from: fromDate,
            date_to: toDate ? toDate : new Date(),
            dpt: sessionStorage.getItem('dpt')
        }, HEADERS).then((response) => {
            if (response.data.length > 0) {
                response.data.map(x => {
                    x.Categorie = x.Categorie ? x.Categorie : ''
                    x.estimated_time = x.estimated_time ? x.estimated_time : ''
                    x.start_follow = moment(x.start_follow).utc().format('YYYY-MM-DD HH:mm')
                    x.end_follow = moment(x.end_follow).utc().format('YYYY-MM-DD HH:mm')
                })
                downloadExcel({
                    fileName: `Task_${moment().utc().format('YYYY-MM-DD')}`,
                    sheet: "sheet1",
                    tablePayload: {
                        header,
                        body: response.data,
                    },
                });
                setShowDatefilter(false);
            } else notifyError("No  data to export");
        });
        calculateHoursDone(selectedUser)
    };

    const relance = async (d) => {
        await Axios.post("/api/task/relance_task", {
            task: d.id_task,
        }, HEADERS).then((response) => {
            if (response.data.affectedRows > 0) {
                setRefreshData(true);
                notifySuccess(response.data.msg);
            } else notifyError(response.data.err);
        });
        calculateHoursDone(selectedUser)
    };

    const handleUpdateModal = (d) => {
        setId_task(d.id_task);
        setTask(d.task);
        setProject(d.project);
        setAssigned_to(d.assigned_to)
        setCategorie(d.ctg_id);
        setCategorieID(d.ctg_id)
        setEstimate_time(d.estimated_time);
        setShowUpdate(true);
    };

    const filtering = () => {
        if (filterType === 1 || filterType === 2) {
            if (filterTexte !== "") {
                if (filterType === 1) {
                    const filtered = all_task1.filter((item) => {
                        return item.task.toLowerCase().includes(filterTexte.toLocaleLowerCase());
                    });
                    setAll_task(filtered)
                } else {
                    const filtered = all_task1.filter((item) => {
                        return item.project.toLowerCase().includes(filterTexte.toLocaleLowerCase());
                    });
                    setAll_task(filtered)

                }
            }
        } else if (filterType === 3 || filterType === 4 || filterType === 6) {
            if (filter_test !== "") {
                if (filterType === 3) {
                    const filtered = all_task1.filter((item) => {
                        return item.assigned_to.toString() === filter_test.toString();
                    });
                    setAll_task(filtered)

                } else if (filterType === 4) {
                    const filtered = all_task1.filter((item) => {
                        return item.estimated_time.toString() === filter_test.toString();
                    });
                    setAll_task(filtered)
                } else {
                    const filtered = all_task1.filter((item) => {
                        return item.status.toLowerCase().includes(filter_test.toLowerCase());
                    });
                    setAll_task(filtered)
                }
            }
        } else {
            if (filterTexte !== '') {
                if (filterType === 5) {
                    const filtered = all_task1.filter((item) => {
                        return item.estimated_time.toString() === filterTexte.toString();
                    });
                    setAll_task(filtered)
                } else {
                    const filtered = all_task1.filter((item) => {
                        return item.total.toString() === filterTexte.toString();
                    });
                    setAll_task(filtered)
                }
            }
        }
        setShowModelfilter(false);
        paginate(1);
    };

    const handleViewModal = async (d) => {
        await Axios.post("/api/task/get_task_details", {
            id: d.id_task,
        }, HEADERS).then(async (response) => {

            const final = await response.data.map(row => {
                return {
                    ...row,
                    start_follow: row.start_follow ? moment(row.start_follow).utc(true) : null,
                    end_follow: row.end_follow ? moment(row.end_follow).utc(true) : null
                }
            })
            console.log(final)
            setGetdetailstask(final);
            setTaskChoosed(d)
        });

        setShowModal(true);
        setMaintenanceData(d);
        calculateHoursDone(selectedUser)
    };

    const handleSeenModal = (d) => {
        setShowModalSeen(true);
        setMaintenanceData(d);
    };

    const handleSeenModalclose = (d) => {
        setShowModalClose(true);
        setMaintenanceData(d);
    };

    const handleComplete = async (d) => {
        let c = await window.confirm("Are you sure you have finished task #" + d.id_task);
        if (c) {
            await Axios.post("/api/task/complete_task", {
                task_id: d.id_task,
            }, HEADERS).then((response) => {
                if (response.status === 200 && response.data.type_action === true) {
                    notifySuccess("Task completed");
                    setRefreshData(true);
                } else
                    notifyError(response.data.errormsg);
            });
        }
        calculateHoursDone(selectedUser)
    };

    const handleDeleteTask = async (d) => {
        let c = await window.confirm("Are you sure you want to delete the task #" + d.id_task);
        if (c) {
            await Axios.post("/api/task/deleteTask", {
                task: d.id_task,
            }, HEADERS).then((response) => {
                if (response.data.status) {
                    notifySuccess("Task deleted");
                    setRefreshData(true);
                } else
                    notifyError(response.data.errormsg);
            });
        }
        calculateHoursDone(selectedUser)
    }

    const handleTypeClose = () => {
        setShowType(false);
        setShowModal(false);
        setShowUpdate(false);
        setEstimate_time("0");
        setTask("");
        setCategorie("");

        setMessage_task("");
        setMessageEstimate_time("");
        setMessaageCategorie("");
    };

    const edit_task = (e) => {
        if (task === "") setMessage_task("Please insert the task");
        else setMessage_task("");
        if (estimate_time === "") setMessageEstimate_time("Enter a estimated time");
        else setMessageEstimate_time("");
        if (categorie === "") setMessaageCategorie("Choose category");
        else setMessaageCategorie("");
        if (project === "") setMessage_project("Specify the project");
        else setMessage_project("");
        if (assignto === 0) setMessageAssign("Please assign task");
        else setMessageAssign("");
        if (
            task !== "" &&
            estimate_time !== "" &&
            categorie &&
            project !== "" &&
            assignto !== 0
        ) {
            Axios.post("/api/task/edit_task", {
                id: id_task,
                task: task,
                estimated_time: estimate_time,
                project: project,
                assignto: assignto,
            }, HEADERS).then((response) => {
                if (response.data.type_action) {
                    handleTypeClose();
                    setRefreshData(true);
                    notifySuccess("Task added");
                } else notifyError(response.data.errormsg);
            });
        }
    };

    const handleCategory = (e) => {
        if (e === -1) {
            setEstimate_time(0)
        } else {
            const filtered = listCategory.filter(cat => cat.ctg_id === parseInt(e, 10))
            setCategorie(filtered[0].ctg_name);
            setEstimate_time(filtered[0].ctg_time)
        }
    };

    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = all_task.slice(indexOfFirstPost, indexOfLastPost);

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage === totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <span onClick={() => paginate(1)} className="p-2">
                                    {"<<"}
                                </span>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage === 1 ? (
                                <span className="p-2" disabled>
                                    {"<"}
                                </span>
                            ) : (
                                <span
                                    onClick={() => paginate(currentPage - 1)}
                                    className="p-2"
                                >
                                    {"<"}
                                </span>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage === number ? (
                                    <span
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red p-2"
                                    >
                                        {number}
                                    </span>
                                ) : (
                                    <span onClick={() => paginate(number)} className="">
                                        {number}
                                    </span>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage === totalPages ? (
                                <span className="p-4" disabled>
                                    {">"}
                                </span>
                            ) : (
                                <span
                                    onClick={() => paginate(currentPage + 1)}
                                    className="p-2"
                                >
                                    {">"}
                                </span>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <span onClick={() => paginate(totalPages)} href="#" className="p-2">
                                    {">>"}
                                </span>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const tableRef = useRef(null);

    const { } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Users table",
        sheet: "Users",
    });

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const add_task = (e) => {
        if (task === "") setMessage_task("Please insert the task");
        else setMessage_task("");
        if (estimate_time === "") setMessageEstimate_time("Enter a estimated time");
        else setMessageEstimate_time("");
        if (categorie === "") setMessaageCategorie("Choose category");
        else setMessaageCategorie("");
        if (project === "") setMessage_project("Specify the project");
        else setMessage_project("");
        if (assignto === 0) setMessageAssign("Please assign task");
        else setMessageAssign("");

        if (task !== "" && estimate_time !== "" && categorie && project !== "") {
            Axios.post("/api/task/add_task", {
                task: task,
                estimated_time: estimate_time,
                project: project,
                assignto: assignto,
            }, HEADERS).then((response) => {
                if (response.data.type_action) {
                    handleTypeClose();
                    setRefreshData(true);
                    notifySuccess("Task added");
                } else notifyError(response.data.errormsg);
            });
        }
    };

    const getData = () => {
        Axios.post("/api/task/get_task", { dpt_id: sessionStorage.getItem('dpt') }, HEADERS).then((response) => {
            setAll_task(response.data);
            setAll_task1(response.data);
        });
        Axios.post("/api/user/get_all_users", {}, HEADERS).then((response) => {
            setAll_user(response.data);
        });
    };

    const getAllDadta = () => {
        Axios.post("/api/task/get_task_user", {
            id: sessionStorage.getItem("id"),
            dpt_id: sessionStorage.getItem('dpt')
        }, HEADERS).then((response) => {
            setAll_task(response.data);
            setAll_task1(response.data);
        });
        Axios.post("/api/user/get_all_users", {}, HEADERS).then((response) => {
            setAll_user(response.data);
            // console.log(sessionStorage.getItem('dpt'));
        });
    };

    const getRequired = () => {
        try {
            setLoadingPermissions(true);
            getPermissionsUser(sessionStorage.getItem("id"), (response) => {
                if (response.data.status) {
                    setPermission(response.data.list)
                    setLoadingPermissions(false);
                }
            })

            getCategorie((response) => {
                if (response.data.status) {
                    setListCategory((response.data.list).filter(prev => prev.ctg_department + "" === sessionStorage.getItem('dpt') + ""))
                }
            })

            getUsersDepartment(sessionStorage.getItem("dpt"), (response) => {
                if (response.data.status) {
                    setListUserDepartment(response.data.list)
                } else {
                    notifyError('Something went wrong')
                }
            })


        } catch (error) {
            notifyError('Internal Error')
        }
    }

    const handleBlur = () => {
        if (updCat.cat_id !== updCat.prev_cat_id) {
            editCategorieTask(updCat, (response) => {
                if (response.data.status) {
                    notifySuccess('Category has benn changed successfully')
                    setEditCtg(false);
                    setTaskEdit([])
                    setUpdCat(updateCat)
                    setRefreshData(true)
                    calculateHoursDone(selectedUser)
                } else {
                    notifyError('Something went Wrong')
                }
            })
        } else {
            setEditCtg(false)
            setTaskEdit([])
        }
    };

    const handlePrepareEditCtg = (d) => {
        setTaskEdit(d);
        setEditCtg(true)
        setUpdCat(() => { return { ...updCat, task_id: d.id_task, cat_id: d.ctg_id, prev_cat_id: d.ctg_id, cat_time: d.ctg_time } })
    }

    const changeValue = (id) => {
        setAll_task(all_task.map(x => {
            if (x.id_task === parseInt(id, 10)) {
                if (x.checked === 1) {
                    return { ...x, checked: 0 };
                }
                else
                    return { ...x, checked: 1 };
            }
            return x;
        }));

        let allChecked = true;
        for (let i = 0; i < all_task.length; i++) {
            console.log(all_task[i].checked);
            if (all_task[i].checked === 0 && all_task[i].status === 'Closed') {
                allChecked = false;
                break;
            }
        }
        setInputChecked(allChecked);
    }

    const completeAll = () => {
        completeAllTask(all_task.filter(e => e.checked === 1), (response) => {
            if (response.data.status) {
                setRefreshData(true)
                notifySuccess('Task has been completed successfully')
            } else {
                notifyError('Something went wrong')
            }
        })
    }

    const calculateHoursDone = (user) => {
        let newData = []
        let total = 0;
        Axios.post("/api/task/getHoursDone", {
            date: dateHours,
            id: user
        }, HEADERS).then((response) => {
            newData = response.data;
            newData.forEach(element => {
                if (element.hour_diff)
                    total += element.hour_diff
            });
            sethoursDone(total)
        })

    }

    useEffect(() => {
        setRefreshData(false);
        if (showall) {
            getAllDadta();
        } else {
            getData();
        }
    }, [refreshData]);

    useEffect(() => {
        getRequired()
        calculateHoursDone(localStorage.getItem("id"))
    }, [])

    useEffect(() => {
        calculateHoursDone(selectedUser)
    }, [dateHours, selectedUser])

    //Visual part
    return (
        <>
            <MenuTestPage />
            {/* Add task modal */}
            <Modal show={showUpdate} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Task<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="ticket-subject"
                                onChange={(e) => {
                                    setTask(e.target.value);
                                }}
                                value={task}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_task}
                            </div>

                            <label className="font-weight-bold">
                                Project<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="ticket-subject"
                                onChange={(e) => {
                                    setProject(e.target.value);
                                }}
                                value={project}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_project}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <div className="" align="left">
                                <label className="font-weight-bold" onClick={() => { console.log(assignto); }}>
                                    Assign to
                                    <span className="text-danger pl-3">*</span>
                                </label>
                            </div>
                            <select
                                id="task-cat"
                                onChange={(e) => {
                                    setAssigned_to(e.target.value);
                                }}
                                value={assignto}
                                name="assignto"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={'99x'} value={0}></option>
                                {all_user ? (
                                    all_user.map((d, index) => {
                                        if (sessionStorage.getItem('dpt') === d.user_department)
                                            return (
                                                <option key={index} value={d.id_user} >
                                                    {" "}
                                                    {d.user_name}{" "}
                                                </option>
                                            )
                                        return null
                                    })
                                ) : (
                                    <option key={'0'} value={""}></option>
                                )}
                            </select>

                            <div id="cstm-contact-error" className="text-danger pt-3">
                                {messageAssign}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <div className="" align="left">
                                <label className="font-weight-bold">
                                    {t("Category")}
                                    <span className="text-danger pl-3">*</span>
                                </label>
                            </div>
                            <select
                                id="cstm-contact"
                                onChange={(e) => {
                                    setCategorieID(e.target.value)
                                    handleCategory(e.target.value)
                                }}
                                value={categorieID}
                                name="categorie"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={-1} value={-1}></option>
                                {listCategory.map((e, index) => {
                                    return (
                                        <option key={index} value={e.ctg_id}>
                                            {e.ctg_name}
                                        </option>
                                    )
                                })}
                            </select>
                            <div id="cstm-contact-error" className="text-danger pt-3">
                                {messaageCategorie}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label className="font-weight-bold">
                                Estimated time(in hour)
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="number"
                                id="ticket-subject"
                                onChange={(e) => {
                                    setEstimate_time(e.target.value);
                                }}
                                value={estimate_time}
                                name="subject"
                                className="form-control rounded-0 cursor-pointer"
                                readOnly
                            />

                            <div id="site-error" className="text-danger pt-2">
                                {messageEstimate_time}
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTypeClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={edit_task}>
                        Update task
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModelfilter} onHide={handleModelClosefilter} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter by {filterType === 1 ? 'task' : filterType === 2 ? 'project' : filterType === 3 ? 'resolved by' : filterType === 4 ? 'category' : filterType === 5 ? 'estimated time' : filterType === 6 ? 'status' : 'total minutes'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group col-xl-12 ">
                            <div className="" align="left">
                                <label className="font-weight-bold">
                                    {filterType === 1 ? 'task' : filterType === 2 ? 'project' : filterType === 3 ? 'resolved by' : filterType === 4 ? 'category' : filterType === 5 ? 'estimated time' : filterType === 6 ? 'status' : 'total minutes'}
                                    <span className="text-danger pl-3 ">*</span>
                                </label>
                            </div>
                            {filterType === 1 || filterType === 2 ?
                                <input
                                    className="form-control rounded-0"
                                    onChange={(e) => setFilterTexte(e.target.value)}
                                />
                                : filterType === 3 || filterType === 4 || filterType === 6 ?
                                    <select
                                        id="cstm-contact"
                                        onChange={(e) => setFilter_test(e.target.value)}
                                        value={filter_test}
                                        name="contact"
                                        className="form-control rounded-0"
                                    >
                                        <option key={0} value={0}>
                                            {""}
                                        </option>
                                        {filterType === 3 ?
                                            listUserDepartment.map((e, index) => {
                                                return (
                                                    <option key={index} value={e.id_user}>
                                                        {e.user_name}
                                                    </option>
                                                )
                                                return
                                            })
                                            :
                                            filterType === 4 ?
                                                listCategory.map((e, index) => {
                                                    if (e.ctg_department + "" === sessionStorage.getItem('dpt') + "")
                                                        return (
                                                            <option key={index} value={e.ctg_time}>
                                                                {e.ctg_name}
                                                            </option>)
                                                })
                                                :
                                                <>
                                                    <option key={"Closed"} value={"Closed"}>
                                                        {"Closed"}
                                                    </option>
                                                    <option key={"Open"} value={"Open"}>
                                                        {"Open"}
                                                    </option>
                                                    <option key={"Started"} value={"Started"}>
                                                        {"Started"}
                                                    </option>
                                                    <option key={"Completed"} value={"Completed"}>
                                                        {"Completed"}
                                                    </option>
                                                </>
                                        }
                                    </select> :
                                    filterType >= 5 ?
                                        <input
                                            type="number"
                                            className="form-control rounded-0"
                                            onChange={(e) => setFilterTexte(e.target.value)}
                                        />
                                        :
                                        <></>

                            }
                            <div id="cstm-code-error" className="text-danger pt-2">
                                {messageMch_filter}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModelClosefilter}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={filtering}>
                        Filter
                    </Button>
                </Modal.Footer>
            </Modal >

            <Modal show={showDatefilter} onHide={handleModelClosefilter} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <div className="text-left font-weight-bold fs-17">From</div>
                            <input
                                type="date"
                                id="ticket-subject"
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {messageFromDate}
                            </div>
                        </div>
                        <div className="form-group mb-4 mt-3">
                            <div className="text-left font-weight-bold fs-17">To</div>
                            <input
                                type="date"
                                onChange={(e) => {
                                    setToDate(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="" className="text-danger pt-2">
                                {MessageToDate}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModelClosefilter}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleDownloadExcel()}>
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showType} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>New Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Task<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="ticket-subject"
                                onChange={(e) => {
                                    setTask(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_task}
                            </div>

                            <label className="font-weight-bold">
                                Project<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="ticket-subject"
                                onChange={(e) => {
                                    setProject(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_project}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <div className="" align="left">
                                <label className="font-weight-bold">
                                    Assign to
                                    <span className="text-danger pl-3">*</span>
                                </label>
                            </div>
                            <select
                                id="task-cat"
                                onChange={(e) => {
                                    setAssigned_to(e.target.value);
                                }}
                                value={assignto}
                                name="assignto"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={98} value={0}></option>
                                {all_user ? (
                                    all_user.map((d, index) => {
                                        if (sessionStorage.getItem('dpt') + "" === d.user_department + "")
                                            return (
                                                <option key={index} value={d.id_user}>
                                                    {d.user_name}{" "}
                                                </option>
                                            )
                                        else
                                            return null
                                    })
                                ) : (
                                    <option key={0} value={""}></option>
                                )}
                            </select>

                            <div id="cstm-contact-error" className="text-danger pt-3">
                                {messageAssign}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <div className="" align="left">
                                <label className="font-weight-bold">
                                    {t("Category")}
                                    <span className="text-danger pl-3">*</span>
                                </label>
                            </div>
                            <select
                                id="cstm-contact"
                                onChange={(e) => {
                                    handleCategory(e.target.value)
                                }}
                                // value={categorie}
                                name="categorie"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={-1} value={-1}></option>
                                {listCategory.map((e, index) => {
                                    if (e.ctg_department + "" === sessionStorage.getItem('dpt') + "")
                                        return (
                                            <option key={index} value={e.ctg_id}>
                                                {e.ctg_name}
                                            </option>
                                        )
                                    return null
                                })}
                            </select>
                            <div id="cstm-contact-error" className="text-danger pt-3">
                                {messaageCategorie}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label className="font-weight-bold">
                                Estimated time(in hour)
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="number"
                                id="ticket-subject"
                                onChange={(e) => {
                                    setEstimate_time(e.target.value);
                                }}
                                value={estimate_time}
                                name="subject"
                                className="form-control rounded-0 cursor-pointer"
                                readOnly
                            />

                            <div id="site-error" className="text-danger pt-2">
                                {messageEstimate_time}
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTypeClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={add_task}>
                        Add task
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Task Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="font-weight-bold fs-15" >
                        <p>Task: <em className="fs-14">{taskChoosed.task}</em></p>
                        <p>Total already attempt: <em className="fs-14">{taskChoosed.total} minute(s)</em></p>
                    </div>
                    <table id="customers" className="table ">
                        <thead className="position-fixe">
                            <tr>
                                <th scope="col" className="w-25px">
                                    #
                                </th>

                                <th scope="col" className="text-left">
                                    Start time
                                    <span data-toggle="modal" data-target="#ticketidModal">
                                        <i className="la la-filter"></i>
                                    </span>
                                </th>

                                <th scope="col" className="text-left">
                                    End time
                                    <span data-toggle="modal" data-target="#ticketidModal">
                                        <i className="la la-filter"></i>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {getdetailstask ? (
                                getdetailstask.map((d, index) => (
                                    <tr key={index} id="" className={"data display-block "}>
                                        <td id="tb-assigned-to" className="text-left">
                                            {d.id_follow_task}
                                        </td>
                                        <td id="tb-created-date" className="text-left">
                                            {d.start_follow != null
                                                ? Moment(d.start_follow).utc().format("yyyy-MM-DD HH:mm")
                                                : ""}
                                        </td>
                                        <td id="tb-created-date" className="text-left">
                                            {d.end_follow != null
                                                ? Moment(d.end_follow).utc().format("yyyy-MM-DD HH:mm")
                                                : ""}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={11} className="text-center pt-20 fs-16">
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTypeClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {
                !loadingPermissions &&
                <div className="col-xl-12 center-block" align="center">
                    <div className="col-xl-8 bg-white mt-50 center-block h-650px" align="center" >
                        <div className="pt-10 w-100 h-80px" style={{ marginLeft: "0.8rem" }}>
                            <h3 className="float-left pt-2 pr-3">List of task </h3>
                            <span className="float-left">
                                <BiRefresh
                                    onClick={() => {
                                        if (showall) {
                                            getAllDadta();
                                        } else {
                                            getData();
                                        }
                                        calculateHoursDone(selectedUser)
                                    }}
                                    className="cursor-pointer display-4"
                                />
                            </span>

                            {permissions.filter(perm => perm.userPerm_code === 100)[0].userPerm_value === 1 &&
                                <button
                                    onClick={() => setShowType(true)}
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-10"
                                    data-toggle="modal"
                                    data-target="#addticketModal"
                                >
                                    {t("Add task")}
                                </button>
                            }

                            {permissions.filter(perm => perm.userPerm_code === 101)[0].userPerm_value === 1 &&
                                <button
                                    onClick={() => {
                                        if (showall) {
                                            getData();
                                            setshowall(false);
                                        } else {
                                            getAllDadta();
                                            setshowall(true);
                                        }
                                    }}
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-info font-weight-bold float-right mr-2"
                                    data-toggle="modal"
                                >
                                    {showall ? "Show all" : "Show mine"}
                                </button>
                            }

                            {permissions.filter(perm => perm.userPerm_code === 102)[0].userPerm_value === 1 &&
                                <button
                                    onClick={() => {
                                        setShowDatefilter(true);
                                    }}
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-primary font-weight-bold float-right mr-2"
                                    data-toggle="modal"
                                >
                                    Export
                                </button>
                            }


                            {permissions.filter(perm => perm.userPerm_code === 108)[0].userPerm_value === 1 && all_task.filter(e => e.checked === 1).length > 1 ?
                                < button
                                    onClick={() => {
                                        completeAll()
                                    }}
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-info font-weight-bold float-right mr-2"
                                    data-toggle="modal"
                                >
                                    Complete
                                </button>
                                : <></>
                            }
                        </div>
                        <div className="pt-10 w-100 h-80px d-flex flex-row ms-1 justify-content-left align-items-center pl-10">
                            <div className="float-left"><input type="date" className="p-2" value={dateHours} onChange={(e) => setdateHours(e.target.value)} /> </div>
                            {
                                permissions.filter(perm => perm.userPerm_code === 124)[0].userPerm_value === 1 &&
                                <div className="float-left">
                                    <select value={selectedUser} onChange={(e) => setselectedUser(e.target.value)} className="p-3 ml-2 w-150">
                                        {all_user ? (
                                            all_user.map((d, index) => {
                                                if (sessionStorage.getItem('dpt') + "" === d.user_department + "")
                                                    return (
                                                        <option key={index} value={d.id_user} >
                                                            {d.user_name}{" "}
                                                        </option>
                                                    )
                                                return null
                                            })
                                        ) : (
                                            <option key={'0'} value={""}></option>
                                        )}

                                    </select>
                                </div>
                            }

                            <div className="float-left ml-2 fs-17 ">Work Hours: <span className="font-weight-bold text-success">{hoursDone.toFixed(2)}</span><span style={{ color: 'red', paddingLeft: 5 }}></span></div>
                        </div>

                        <div className="d-flex flex-column h-450px border overflow-auto">
                            <table id="customers" className="table " ref={tableRef}>
                                <thead className="position-fixe">
                                    <tr>
                                        <th scope="col" className="w-25px"></th>
                                        <th scope="col" className="w-25px">
                                            {/* <input type="checkbox" onChange={(e) => checkAll(e.target.checked)} checked={inputChecked} /> */}
                                        </th>
                                        <th scope="col" className="w-25px" onClick={() => { }}>
                                            #
                                        </th>

                                        <th scope="col" className="text-left">
                                            Task
                                            <span
                                                onClick={() => {
                                                    setShowModelfilter(true);
                                                    setFilterType(1)
                                                }}
                                            >
                                                <i className="la la-filter"></i>
                                            </span>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Project
                                            <span
                                                href="#"
                                                onClick={() => {
                                                    setShowModelfilter(true);
                                                    setFilterType(2)
                                                }}
                                            >
                                                <i className="la la-filter"></i>
                                            </span>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Resolved by
                                            {permissions.filter(perm => perm.userPerm_code === 114)[0].userPerm_value === 1 &&
                                                <span
                                                    onClick={() => {
                                                        setShowModelfilter(true);
                                                        setFilterType(3)
                                                    }}
                                                >
                                                    <i className="la la-filter"></i>
                                                </span>
                                            }
                                        </th>

                                        <th scope="col" className="text-left">
                                            Category
                                            <span
                                                onClick={() => {
                                                    setShowModelfilter(true);
                                                    setFilterType(4)
                                                }}
                                            >
                                                <i className="la la-filter"></i>
                                            </span>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Estimated time (in min)
                                            <span
                                                onClick={() => {
                                                    setShowModelfilter(true);
                                                    setFilterType(5)
                                                }}
                                            >
                                                <i className="la la-filter"></i>
                                            </span>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Status
                                            <span
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                                onClick={() => {
                                                    setShowModelfilter(true);
                                                    setFilterType(6)
                                                }}
                                            >
                                                <i className="la la-filter"></i>
                                            </span>
                                        </th>
                                        <th scope="col" className="text-left">
                                            Total minutes
                                            <span
                                                onClick={() => {
                                                    setShowModelfilter(true);
                                                    setFilterType(7)
                                                }}
                                            >
                                                <i className="la la-filter"></i>
                                            </span>
                                        </th>
                                        <th scope="col" className="text-left">
                                            <span data-toggle="modal" data-target="#statusModal">
                                                {/* <i className="la la-filter"></i> */}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody id="resultdata">
                                    {currentPosts ? (
                                        currentPosts.map((d, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    id='td"+ d[i].m_id +"'
                                                    className={
                                                        "data" +
                                                        (d.status === "Completed"
                                                            ? d.estimated_time < d.total
                                                                ? "text-danger"
                                                                : "text-info "
                                                            : "")
                                                    }
                                                >
                                                    <td className="align-middle text-center">
                                                        <Dropdown className="dropdown">
                                                            <Dropdown.Toggle
                                                                variant="dropdown-basic"
                                                                className="w-60px py-0 text-white p-0 m-0"
                                                            >
                                                                <div
                                                                    className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                    data-toggle="dropdown"
                                                                >
                                                                    <span
                                                                        type="button"
                                                                        className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                    >
                                                                        <i className="ki ki-bold-more-hor"></i>
                                                                    </span>
                                                                </div>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="">

                                                                {permissions.filter(perm => perm.userPerm_code === 103)[0].userPerm_value === 1 && d.status === "Completed" ? (
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => relance(d)}
                                                                        className="dropdown-item border-bottom border-top"
                                                                    >
                                                                        <i className="la la-refresh"></i>{" "}
                                                                        <span className="pl-3">Re-start</span>
                                                                    </Dropdown.Item>
                                                                ) : null}

                                                                {permissions.filter(perm => perm.userPerm_code === 104)[0].userPerm_value === 1 &&
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => handleUpdateModal(d)}
                                                                        className="dropdown-item border-bottom border-top"
                                                                    >
                                                                        <i className="far fa-edit"></i>{" "}
                                                                        <span className="pl-3">Edit task</span>
                                                                    </Dropdown.Item>
                                                                }

                                                                {permissions.filter(perm => perm.userPerm_code === 106)[0].userPerm_value === 1 &&
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => restartTask(d)}
                                                                        className="dropdown-item border-bottom"
                                                                    >
                                                                        <i className="la la-refresh"></i>{" "}
                                                                        <span className="pl-3">Reset</span>
                                                                    </Dropdown.Item>
                                                                }

                                                                {permissions.filter(perm => perm.userPerm_code === 107)[0].userPerm_value === 1 &&
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={() => handleDeleteTask(d)}
                                                                        className="dropdown-item border-bottom"
                                                                    >
                                                                        <i className="la la-trash"></i>{" "}
                                                                        <span className="pl-3">Delete</span>
                                                                    </Dropdown.Item>
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                    <td className="align-middle ">
                                                        <input
                                                            type="checkbox"
                                                            disabled={d.status === "Completed" || d.status === "Open" ? true : false}
                                                            checked={d.checked}
                                                            value={d.id_task}
                                                            onChange={(e) => { }}
                                                            onClick={(e) => { changeValue(e.target.value) }}
                                                        />
                                                    </td>

                                                    <td id="tb-assigned-to" className="text-left align-middle ">
                                                        {d.id_task}
                                                    </td>
                                                    <td id="tb-created-date" className={`text-left align-middle  ${permissions.filter(perm => perm.userPerm_code === 105)[0].userPerm_value === 1 && 'cursor-pointer hover_underlined'}`} onClick={() => { if (permissions.filter(perm => perm.userPerm_code === 105)[0].userPerm_value === 1) handleViewModal(d) }}>
                                                        {d.task}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        {d.project}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        {d.user_name}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        {editCtg && taskEdit.id_task === d.id_task ?
                                                            <div className="form-group mb-4 ">
                                                                <select
                                                                    onChange={(e) => {
                                                                        setUpdCat(() => { return { ...updCat, cat_id: parseInt(e.target.value, 10), cat_time: listCategory.filter(cat => cat.ctg_id === parseInt(e.target.value, 10))[0].ctg_time } })
                                                                    }}
                                                                    value={updCat.cat_id}
                                                                    name="categorie"
                                                                    className="form-control border-0 cursor-pointer select-min-width"
                                                                    onBlur={handleBlur}
                                                                    autoFocus

                                                                >
                                                                    <option key={-1} value={-1}></option>
                                                                    {listCategory.map((e, index) => {
                                                                        return (
                                                                            <option key={index} value={e.ctg_id}>
                                                                                {e.ctg_name}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            :
                                                            <span className={permissions.filter(perm => perm.userPerm_code === 104)[0].userPerm_value === 1 && `cursor-pointer hover_opacity`} onClick={() => { if (permissions.filter(perm => perm.userPerm_code === 104)[0].userPerm_value === 1) handlePrepareEditCtg(d) }}>{d.ctg_name ? d.ctg_name : '__'}</span>
                                                        }
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        {editCtg && taskEdit.id_task === d.id_task ? updCat.cat_time : d.estimated_time}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        {d.status}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        {d.total}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left align-middle ">
                                                        <div className="d-flex">
                                                            {d.status === "Open" ? (
                                                                <button
                                                                    onClick={() => {
                                                                        handleSeenModal(d);
                                                                    }}
                                                                    className="btn btn-sm bg-success text-white"
                                                                >
                                                                    Start
                                                                </button>
                                                            ) : d.status === "Closed" ? (
                                                                <>
                                                                    <button
                                                                        onClick={() => {
                                                                            handleSeenModal(d);
                                                                        }}
                                                                        className="btn btn-sm bg-success text-white"
                                                                    >
                                                                        Start
                                                                    </button>

                                                                    {permissions.filter(perm => perm.userPerm_code === 108)[0].userPerm_value === 1 &&

                                                                        <button
                                                                            onClick={() => {
                                                                                handleComplete(d);
                                                                            }}
                                                                            className="btn btn-sm bg-info text-white ml-5"
                                                                        >
                                                                            Complete
                                                                        </button>
                                                                    }
                                                                </>
                                                            ) : d.status === "Started" ? (
                                                                <button
                                                                    onClick={() => {
                                                                        handleSeenModalclose(d);
                                                                    }}
                                                                    className="btn btn-sm bg-danger text-white"
                                                                >
                                                                    Close
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={11} className="text-center pt-20 fs-16">
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {showModalSeen ? (
                        <StartTask
                            show={showModalSeen}
                            onHide={() => {
                                setShowModalSeen(false);
                                setRefreshData(true);
                            }}
                            info={getmaintenancedata}
                        />
                    ) : showModalClose ? (
                        <CloseTask
                            show={showModalClose}
                            onHide={() => {
                                setShowModalClose(false);
                                setRefreshData(true);
                            }}
                            info={getmaintenancedata}
                        />
                    ) : null}

                    <div className="card-footer pt-2 pb-2 col-8">
                        <div className="d-flex justify-content-center">
                            <Pagination
                                postsPerPage={linePerPage}
                                totalPosts={all_task.length}
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </div >
            }
            <Footer />
        </>
    );
}

export default Home;
