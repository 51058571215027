import { useEffect, useState } from "react";
import MenuTestPage from "./header_mobile";
import { HiPlus } from 'react-icons/hi';
import { BiGroup, BiRefresh } from "react-icons/bi";
import { MdPerson } from "react-icons/md";
import { getUsers, notifyError } from "../Function/User";
import ControlledAccordions from "./Accordion";
import { ModalAddUser } from "../Modal/User";
import { getPermissionsUser } from "../Function/User";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";

export default function User() {
    const [refresh, setRefresh] = useState(false)
    const [listUser, setListUser] = useState([])
    const [modalUser, setModalUser] = useState(false)
    const [typeModal, setTypeModal] = useState('')
    const [permissions, setPermission] = useState([])
    const [showAll, setShowAll] = useState(false)
    const [loadingPermissions, setLoadingPermissions] = useState(true);

    const navigate = useNavigate();

    const getRequired = () => {
        try {
            getUsers((response) => {
                if (response.data.status) {
                    setListUser(response.data.list)
                } else {
                    notifyError('Internal Error')
                }
            })

            setLoadingPermissions(true);
            getPermissionsUser(sessionStorage.getItem("id"), (response) => {
                if (response.data.status) {
                    setPermission(response.data.list)
                    setLoadingPermissions(false);
                    if (response.data.list.filter(perm => perm.userPerm_code === 109)[0].userPerm_value === 0) {
                        navigate('/Home')
                    }
                }
            })
        } catch (error) {
            notifyError('Internal Error')
        }
    }

    useEffect(() => {
        if (refresh) {
            getUsers((response) => {
                if (response.data.status) {
                    setListUser(response.data.list)
                } else {
                    notifyError('Internal Error')
                }
            })
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        getRequired()
    }, [])
    return (
        <div>

            <MenuTestPage />
            {!loadingPermissions &&
                <div className="col-xl-12 center-block" align="center">
                    <ModalAddUser open={modalUser} setOpen={setModalUser} refresh={refresh} setRefresh={setRefresh} type={typeModal} allDepartment={permissions.filter(perm => perm.userPerm_code === 122)[0].userPerm_value} />
                    <div className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px" >
                        <div className="pt-10 w-100 h-80px" style={{ marginLeft: "0.8rem" }}>
                            <h3 className="float-left pt-2 pr-3">List of user</h3>
                            <span className="float-left">
                                <BiRefresh
                                    onClick={() => {
                                        getRequired()
                                    }}
                                    className="cursor-pointer display-4"
                                />
                            </span>
                            {permissions.filter(perm => perm.userPerm_code === 113)[0].userPerm_value === 1 &&
                                <button onClick={() => { setModalUser(true); setTypeModal('Add') }} className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-10" >
                                    <HiPlus size={20} />
                                </button>
                            }
                            {permissions.filter(perm => perm.userPerm_code === 114)[0].userPerm_value === 1 ?

                                <button
                                    onClick={() => {
                                        if (showAll) {
                                            setShowAll(false);
                                        } else {
                                            setShowAll(true);
                                        }
                                    }} className="btn btn-defaut text-white bg-info font-weight-bold float-right mr-10" >

                                    {showAll ? <MdPerson /> : <BiGroup size={20} />}

                                </button> : <></>}
                            {/* : <></>
                            : null
                        } */}
                        </div>
                        <div className="p-10 min-h-550px max-h-550px overflow-auto" align="left">
                            <ControlledAccordions list={listUser} setList={setListUser} setRefreshList={setRefresh} showAll={showAll} permissions={permissions} />
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}